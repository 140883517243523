var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('form',{staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSignIn.apply(null, arguments)}}},[_c('div',{staticClass:"login-form__hellow-gashu-text",class:{ 'login-form__hellow-gashu-text_show': _vm.helloGashuShow }},[_vm._v(" Привет от "),_c('span',[_vm._v("Гашу:)")])]),_c('img',{staticClass:"logo",attrs:{"src":require(`assets/images/${_vm.logo}.svg?inline`)},on:{"click":_vm.onClickLogo}}),_c('div',{staticClass:"crm-input-block"},[_c('base-input',{staticClass:"login-form__input",attrs:{"error":!!_vm.errors.username,"errors":_vm.errors.username,"input-style":{
          padding: '21px 20px',
        },"placeholder":"Логин","fluid":""},on:{"blur":function($event){return _vm.validateField('username', _vm.login)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.metaKey)return null;return _vm.submit.apply(null, arguments)}},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),_c('base-input',_vm._g({ref:"password",staticClass:"login-form__input",class:{ 'mb-4': !!_vm.errors.password },attrs:{"error":!!_vm.errors.password,"errors":_vm.errors.password,"input-style":{
          padding: '21px 20px',
        },"type":_vm.showPassword ? 'text' : 'password',"placeholder":"Пароль","fluid":""},on:{"blur":function($event){return _vm.validateField('password', _vm.password)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.metaKey)return null;return _vm.submit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('icon-eye',{class:[ 'icon-eye', { active: _vm.showPassword }],on:{"click":_vm.toggleShowPassword}})]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},{
          blur: _vm.onblurPassword,
        }))],1),_c('base-checkbox',{staticClass:"login-form__checkbox",attrs:{"align-top":""},model:{value:(_vm.saveAuth),callback:function ($$v) {_vm.saveAuth=$$v},expression:"saveAuth"}},[_vm._v(" Сохранить логин и пароль для входа в профиль ")]),_c('b-button',{staticClass:"login-form__button",attrs:{"type":"submit","variant":"outline-primary","pill":""}},[_vm._v(" ВОЙТИ ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }